<template>
  <div class="register">
    <main>
      <section id="form_detail">
        <div class="w1300">

          <!-- 表單主標題 -->
          <div class="main_title_box flex_box">
            <div class="left">
              <h3 class="main_title txt-bold">課程活動紀錄</h3>
              <div class="img_box">
                <img src="@/statics/img/index/icon_title2.svg" alt="">
              </div>
            </div>
            <div class="right">
              <form id="register_record_filter" @submit.prevent>
                <div class="select_box payment_status">
                  <p class="small txt-bold txt-dark_gray">篩選狀態</p>
                  <select
                    id="payment_status"
                    name="payment_status"
                    v-model="pay_status"
                    @change="conditionFilter"
                  >
                    <option value="">全部</option>
                    <option value="0">未繳費</option>
                    <option value="1">已繳費</option>
                  </select>
                </div>
                <div class="text_filter_box">
                  <div class="text_filter">
                    <p class="small txt-bold txt-dark_gray">搜尋課程/活動名稱</p>
                    <div class="input_wrapper">
                      <input
                        class="search"
                        type="text"
                        name="key"
                        placeholder="課程/活動名稱"
                        v-model.trim="keyword"
                      >
                      <button
                        type="button"
                        @click="conditionFilter"
                      >查詢</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <form id="form_account_search_fee" @submit.prevent>

            <div class="user_box">
              <h4 class="txt-dark_green txt-bold mg-b-50">
                {{ user.realname }}，您好<br><br>
                <template v-if="parseInt(level, 10) === 2 || parseInt(level, 10) === 3">
                  目前您的 課程/活動 總時數為 <span class="h3 txt-light_green">
                  {{ credits.hours }}</span> 小時<br><br>
                  尚餘 <span class="h3 txt-red">{{ credits.lost_hours }}</span> 小時即可達申請 專科醫師會員 資格。
                </template>
              </h4>
              <button
                type="button"
                class="btn p"
                @click="logoutRequest"
              >使用者登出</button>
            </div>

            <div class="main_description_box mg-b-50">
              <h4 class="main_description_title txt-light_green txt-bold">說明</h4>
              <ul class="number_list mg-b-10">
                <li>
                  <p>於課程結束5天後開放下載收據與上課證明。</p>
                </li>
                <li>
                  <p>如需新增、修改收據抬頭或報名資訊，請Email或來電告知。</p>
                </li>
                <li>
                  <p>如當天未完成簽到及簽退，則無法下載上課證明。</p>
                </li>
                <li>
                  <p>如需退費，請於開課一週前聯繫本會秘書處，並E-MAIL退款帳戶存摺封面照片或影本，學會將扣除行政處理費200元後退款至您的指定帳戶，逾期恕不受理退費。</p>
                </li>
              </ul>
              <p>聯絡電話：{{ contact.phone }}</p>
              <p>電子郵件：{{ contact.email }}</p>
            </div>

            <!-- table area -->
            <div class="table_box">
              <table class="txt-center">
                <thead>
                  <tr>
                    <th class="td-main txt-left"><p>{{ tableHead1 }}</p></th>
                    <th class="min100"><p>{{ tableHead2 }}</p></th>
                    <th class="min180"><p>{{ tableHead3 }}</p></th>
                    <th class="min100"><p>{{ tableHead4 }}</p></th>
                    <th class="min100"><p>{{ tableHead5 }}</p></th>
                    <th class="min240"><p>{{ tableHead6 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in tableData" :key="data.id">
                    <td :data-title="tableHead1" class="td-main txt-left">
                      <p>{{ activityName(data) }}</p>
                    </td>
                    <td :data-title="tableHead2" class="nowrap">
                      <p v-if="activityHour(data)">
                        {{ activityHour(data) }}小時
                      </p>
                    </td>
                    <td :data-title="tableHead3" class="nowrap">
                      <p>{{ activityPaytime(data) }}</p>
                    </td>
                    <td :data-title="tableHead4">
                      <p>{{ activityPrice(data) }}</p>
                    </td>
                    <td
                      :data-title="tableHead5"
                      class="nowrap"
                      :class="{
                        'txt-light_green': activityStatus(data) === '已繳費',
                        'txt-red': activityStatus(data) === '未繳費' || activityStatus(data) === '已退費',
                      }"
                    >
                      <p v-if="activityPrice(data) !== 0">{{ activityStatus(data) }}</p>
                    </td>
                    <td :data-title="tableHead6" class="td-download td-download-multiple txt-left">
                      <div class="download_box">
                        <!-- 課程完成(線上課程：完成測驗 / 學術、年會：發放積分) & 非免費課程 才開啟列印收據按鈕 -->
                        <template v-if="isClassCompleted(data) && activityPrice(data) > 0">
                          <div
                            class="small download"
                            @click="updateReceiptInfo(data)"
                          >下載收據</div>
                        </template>

                        <!-- 課程完成(線上課程：完成測驗 / 學術、年會：發放積分) 才開啟列印上課證明按鈕 -->
                        <template v-if="isClassCompleted(data)">
                          <div
                            class="small download"
                            @click="updateAttendInfo(data)"
                          >上課證明</div>
                        </template>

                        <!-- 無條件：常駐功能 -->
                        <button
                          type="button"
                          class="small payment_detail_btn"
                          @click="showRegisterInfo(data)"
                        >報名資訊</button>

                        <!-- 繳費功能：根據線上課程、學術活動、年會前往不同繳費頁面 -->
                        <template v-if="activityStatus(data) === '未繳費' && activityPrice(data) > 0">
                          <button
                            type="button"
                            class="small payment_fail_btn"
                            @click="goToPay(data)"
                          >前往繳費</button>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>

        <Pagination
          :pageButton="pageButton"
          :page="page"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />
      </section>
    </main>

    <!-- register info modal -->
    <div
      class="modal_container-paymentDetail"
      :class="{ active: modalActive }"
      @click="closeModal"
    >
      <div class="modal" @click.stop>
        <div class="close_btn h2" @click="closeModal"><span>×</span></div>
        <!-- form area -->
        <div class="form_box">
          <form id="">

            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">報名資料</h4>
              </div>
            </div>

            <div class="form_interactive">
              <div class="fixed_info w50">
                <p class="fixed_info_title">姓名</p>
                <p class="fixed_info_content">{{ userName(user) }}</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">身分證字號/居留證號碼</p>
                <p class="fixed_info_content">{{ userIdNum(user) }}</p>
              </div>
              <!-- <template v-if="user.jobs && user.jobs.length > 0">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">任職院所</p>
                  <p class="fixed_info_content">{{ user.jobs }}</p>
                </div>
              </template>
              <template v-if="user.jobs && user.jobs.length > 0">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">職稱類別</p>
                  <p class="fixed_info_content">{{ user.jobs }}</p>
                </div>
              </template> -->
              <div class="fixed_info w50">
                <p class="fixed_info_title">連絡電話</p>
                <p class="fixed_info_content">{{ userOfficeTel(user) }}</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">行動電話</p>
                <!-- <p class="fixed_info_content">{{ applyPhone(registerInfo) }}</p> -->
                <p class="fixed_info_content">{{ applyPhone(user) }}</p>
              </div>
              <div class="fixed_info w100">
                <p class="fixed_info_title">E-Mail</p>
                <!-- <p class="fixed_info_content">{{ applyEmail(registerInfo) }}</p> -->
                <p class="fixed_info_content">{{ applyEmail(registerInfo) }}</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">收據抬頭</p>
                <p class="fixed_info_content">{{ applyTitle(registerInfo) }}</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">繳費方式</p>
                <p class="fixed_info_content">{{ applyPaymentType(registerInfo) }}</p>
                <!-- <p class="fixed_info_content">信用卡</p> -->
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">繳費日期</p>
                <p class="fixed_info_content">{{ activityPaytime(registerInfo) }}</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">繳費金額</p>
                <p class="fixed_info_content">{{ activityPrice(registerInfo) }}</p>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/** Libraries */
import _ from 'lodash';

/** Components */
import Pagination from '@/components/Pagination.vue';

/** Functions */
import { setAxiosHeaders } from '@/boot/axios';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  fractionSearch,
  classList,
  userInfo,
  downloadReceipt,
  downloadAttendence,
} from '@/lib/http';

export default {
  name: 'RegisterRecord',
  data() {
    return {
      /** Static Data */
      modalActive: false,
      hourLimit: 60,
      contact: {
        phone: '0988-206590',
        email: 'hmca.tw@gmail.com',
      },
      tableHead1: '課程 / 活動名稱',
      tableHead2: '時數',
      tableHead3: '繳費日期',
      tableHead4: '金額',
      tableHead5: '狀態',
      tableHead6: '操作',
      pageButton: 5,

      /** Search Params */
      pay_status: '',
      keyword: '',
      limit: 10,
      page: 1,

      /** Axios Response */
      credits: {},
      user: {},
      tableData: [],
      totalPage: null,
      registerInfo: {},
      // order_detail_memo: {},
    };
  },
  created() {
    fractionSearch().then((result) => {
      this.credits = result.data;
    });
    this.conditionFilter();
    if (this.hasToken) {
      userInfo().then((result) => {
        this.user = result;
      });
    }
  },
  computed: {
    ...mapState([
      'realname',
      'level',
    ]),
    ...mapGetters([
      'hasToken',
    ]),
  },
  methods: {
    ...mapActions([
      'openModal',
      'logout',
    ]),
    /** 課程活動類型 */
    activityCategory(data) {
      const type = parseInt(_.get(data, 'type', 0), 10);
      if (type === 2) return 'course';
      if (type === 3) return 'event';
      if (type === 4) return 'annual';
      return '';
    },
    /** 線上課程完成狀態 */
    isClassCompleted(data) {
      return _.get(data, 'class_completed', null);
    },
    /** 課程/活動名稱 */
    activityName(data) {
      return _.get(data, 'class_name', '');
    },
    /** 時數 */
    activityHour(data) {
      return _.get(data, 'hour.num', '');
    },
    /** 繳費日期 */
    activityPaytime(data) {
      return _.get(data, 'order.pay_time', '');
    },
    /** 金額 */
    activityPrice(data) {
      const payID = _.get(data, 'order.payment.pay_id', null);
      if (parseInt(payID, 10) === 0) {
        return 0;
      }
      return _.get(data, 'total', '');
    },
    /** 狀態 */
    activityStatus(data) {
      const payID = _.get(data, 'order.payment.pay_id', null);
      const status = _.get(data, 'order.payment.status', null);
      const completed = _.get(data, 'order.payment.completed', null);
      const deleted = _.get(data, 'deleted', null);
      if (payID === -1) {
        return '-';
      }
      if (deleted !== null) {
        return '已退費';
      }
      if (status && completed) {
        return '已繳費';
      }
      return '未繳費';
    },

    /** 報名資料：姓名 */
    userName(user) {
      return _.get(user, 'realname', '');
    },
    /** 報名資料：身分證字號 */
    userIdNum(user) {
      return _.get(user, 'id_number', '');
    },
    /** 報名資料：市話 */
    userOfficeTel(user) {
      return _.get(user, 'jobs.office_tel', '');
    },
    /** 報名資料：手機~ */
    // applyPhone(data) {
    //   return _.get(data, 'Orders.phone', '');
    // },
    applyPhone(user) {
      return _.get(user, 'phone', '');
    },
    /** 報名資料：email */
    // applyEmail(data) {
    //   return _.get(data, 'Orders.email', '');
    // },
    applyEmail(data) {
      return _.get(data, 'email', '');
    },
    /** 報名資料：抬頭 */
    applyTitle(data) {
      return _.get(data, 'title', '');
    },
    /** 報名資料：繳費方式 */
    applyPaymentType(data) {
      const target = _.get(data, 'order.payment.pay_id', '');
      if (target) {
        if (parseInt(target, 10) === 1) {
          return '信用卡';
        }
        if (parseInt(target, 10) === -1) {
          return '-';
        }
        if (parseInt(target, 10) === 4) {
          return '現場繳費';
        }
        return '';
      }
      return '';
    },
    conditionFilter() {
      classList({
        pay_status: this.pay_status,
        keyword: this.keyword,
        page: this.page,
        limit: this.limit,
      }).then((result) => {
        if (typeof result === 'string') {
          this.openModal({
            message: result,
            url: '/login',
          });
        } else {
          this.tableData = result.data;
          this.totalPage = result.pageCount;
        }
        // this.registerInfo = result.data[0].payment_notify;
        // this.order_detail_memo = result.data[0].order_detail_memo;
      });
    },
    updatePage(value) {
      this.page = value;
      this.$router.push({ query: { num: value } });
    },
    showRegisterInfo(data) {
      this.registerInfo = data;
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
    },
    logoutRequest() {
      this.logout()
        .then(() => {
          this.$router.push({ path: '/' });
          setAxiosHeaders(null);
        });
    },
    /** 收據資料存進cookies */
    updateReceiptInfo(data) {
      // console.log('receipt', data);
      const isPrintAvailable = _.get(data, 'print', null);
      if (isPrintAvailable) {
        const target = _.get(data, 'uuid', null);
        if (target) {
          downloadReceipt({
            order_detail_uuid: target,
          }).then((result) => {
            if (result.data.status) {
              this.$cookies.set('receiptInfo', JSON.stringify(result.data.result.data));
              const routeData = this.$router.resolve({ path: '/receipt' });
              window.open(routeData.href, '_blank');
            } else {
              this.openModal({
                message: result.data.message,
              });
            }
          });
        }
      } else {
        this.openModal({
          message: '未開放列印，如欲列印請洽學會',
        });
      }
    },
    /** 上課證明資料存進cookies */
    updateAttendInfo(data) {
      const isPrintAvailable = _.get(data, 'hour.print', null);
      // console.log('attend', data);
      if (isPrintAvailable) {
        const target = _.get(data, 'hour.uuid', null);
        if (target) {
          downloadAttendence({
            course_hours_uuid: target,
          }).then((result) => {
            if (result.data.status) {
              this.$cookies.set('attendInfo', JSON.stringify(result.data.result.data));
              const routeData = this.$router.resolve({ path: '/attendance' });
              window.open(routeData.href, '_blank');
            } else {
              this.openModal({
                message: result.data.message,
              });
            }
          });
        }
      } else {
        this.openModal({
          message: '未開放列印，如欲列印請洽學會',
        });
      }
    },
    goToPay(data) {
      /** 線上課程 */
      if (parseInt(data.type, 10) === 2) {
        const courseIdArray = [];
        courseIdArray.push(data.class_uuid);
        // console.log('target', courseIdArray);
        this.$cookies.set('courseInCart', JSON.stringify(courseIdArray));
        this.$router.push({ path: '/register/online/grocery' });
      } else {
        const orderID = _.get(data, 'order.uuid', '');

        if (orderID) {
          this.$router.push({ path: `/register/record/payment?order_id=${orderID}` });
        } else {
          this.openModal({
            message: '尚未產生未繳費記錄',
          });
        }
      }
    },
  },
  watch: {
    page() {
      this.conditionFilter();
    },
  },
  components: {
    Pagination,
  },
};
</script>
